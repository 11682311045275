import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: "Satya Sandeep",
    location: "Bengaluru, India",
    email: "hello@satyasandeep.in",
    availability: "Open for work",
  };

  return (
    <>
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
