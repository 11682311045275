import { useState } from "react";
import Typewriter from "typewriter-effect";
import SocialIcons from "../../components/SocialIcons";
import resume from "../about/SatyaResume.pdf";

const Landing = ({ name }) => {
  const styles = {
    landing: {
      height: "calc(100% - 93px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    landingImage: {
      position: "absolute",
      bottom: "0",
      opacity: "0.3",
      mixBlendMode: "lighten",
      height: "80%",
    },

    textContainer: {
      display: "flex",
      flexDirection: "column",
      letterSpacing: "1px",
      textAlign: "center",
      zIndex: "1",
      color: "#fff",
      textShadow: "1px 1px 3px #000",
    }, nameTwo: {
      color: "#fff",
      fontWeight: "700",
      marginTop: "-5vh",
      // paddingBottom: "28px",
    },

    name: {
      color: "#fff",
      fontWeight: "700",
      marginTop: "-100px",
      paddingBottom: "28px",
    },
  };

  const [downloading, setDownloading] = useState(false);
  const handleDownload = () => {
    setDownloading(true);
    const link = document.createElement("a");
    link.href = resume;
    link.download = "Satya Resume.pdf";
    link.onload = () => {
      link.remove();
    };
    document.body.appendChild(link);
    setDownloading(false);
    link.click();
  };

  return (
    <section className="landing" style={styles.landing}>
      <div className="textContainer" style={styles.textContainer}>
        <h1 className="name" style={styles.name}>
          Hi, I am  Satya Sandeep
        </h1>

        <div className="description">
          <Typewriter
            className="description"
            onInit={(typewriter) => {
              typewriter
                .changeDelay(80)
                .typeString("I'm a Software Engineer")
                .pauseFor(1500)
                .deleteAll()
                .changeDelay(80)
                .typeString("I'm a Node JS Developer")
                .pauseFor(1500)
                .deleteAll()
                .changeDelay(80)
                .typeString("I'm a React Developer")
                .pauseFor(1500)
                .deleteAll()
                // .typeString("Coding. ")
                // .pauseFor(500)
                // .typeString("Coding. ")
                // .pauseFor(500)
                // .typeString("Coding.")

                .start();
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button className="btn downloadCV" onClick={handleDownload} disabled={downloading}>
            {downloading ? 'Downloading...' : 'Download Resume'}
          </button>
        </div>


      </div>

      <SocialIcons />
    </section>
  );
};

export default Landing;
